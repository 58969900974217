import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import ScrollTop from "../LandingPageSections/ScrollTop";
// Styles
import "../../Styles/LandingPage/Contact.css";

const Contact = () => {
  const [phoneNumber, setPhoneNumber] = useState("");

  const handlePhoneNumberChange = (event) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setPhoneNumber(numericValue);
  };

  // Email JS Implementation

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    alert("Response Submitted! We’ll get back in touch soon! ✅");

    emailjs
      .sendForm(
        "service_kgim5ia",
        "template_65cdwly",
        form.current,
        "aE5Fr634lVoEbV4dk",
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        },
      );
  };

  return (
    <>
      <ScrollTop />
      <section className='contact-section'>
        <div className='contact-heading'>
          <h6>
            <strong>WE HOPE YOU LIKED OUR PAGE</strong>
          </h6>
          <h1>
            <strong>
              LET'S <span id='contact-id'>CONTACT</span>
            </strong>
          </h1>
        </div>
        <div className='connect-input'>
          <form ref={form} onSubmit={sendEmail}>
            <label htmlFor='name'>
              Name
              <input type='name' name='from_name' id='name-input' required />
            </label>
            <label htmlFor='email'>
              Email
              <input type='email' name='email' id='email-input' required />
            </label>
            <label htmlFor='number'>
              Phone No.
              <input
                minLength={10}
                maxLength={10}
                type='tel'
                value={phoneNumber}
                // pattern='/^-?\d+\.?\d*$/'
                onChange={handlePhoneNumberChange}
                name='phone'
                id='phone-number-input'
                required
              />
            </label>
            <label htmlFor='project-details'>
              Project Details
              <textarea
                name='message'
                id='project-details'
                cols='30'
                rows='10'
                required></textarea>
            </label>
            <input type='submit' value='SUBMIT' className='form-submit-btn' />
          </form>
        </div>
      </section>
    </>
  );
};

export default Contact;
