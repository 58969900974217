import React from "react";
import ScrollTop from "../LandingPageSections/ScrollTop";
// Styles
import "../../Styles/LandingPage/HearOurClients.css";

// Reviews
import Review1 from "../../Resources/Images/5_star_1.png";
import Review2 from "../../Resources/Images/4_star_1.png";
import Review3 from "../../Resources/Images/4_5_star_1.png";
import Review4 from "../../Resources/Images/4_star_2.png";
import Review5 from "../../Resources/Images/5_star_2.png";
import Review6 from "../../Resources/Images/4_5_star_2.png";

const HearOurClients = () => {
  return (
    <>
      <ScrollTop />
      <section className='hear-our-clients-section'>
        <div className='container'>
          <h1 className='services-heading '>
            <strong>
              <span className='stats-we-keep-count'> HEAR</span> OUR
              <span className='stats-we-keep-count'> CLIENTS.</span>
            </strong>
          </h1>
        </div>
        <div className='hear-our-clients-reviews'>
          <div className='row text-center'>
            <div className='col-lg-4 col-md-4'>
              <div>
                <img src={Review1} alt='review-1' />
              </div>
              <div>
                <img src={Review2} alt='review-2' />
              </div>
            </div>
            <div className='col-lg-4 col-md-4'>
              <div>
                <img src={Review3} alt='review-3' />
              </div>
              <div>
                <img src={Review4} alt='review-4' />
              </div>
            </div>
            <div className='col-lg-4 col-md-4'>
              <div>
                <img src={Review5} alt='review-5' />
              </div>
              <div>
                <img src={Review6} alt='review-6' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HearOurClients;
