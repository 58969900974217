import React from "react";
import { Link } from "react-router-dom";
const PageNotFound = () => {
  return (
    <div className='error-text'>
      <h1>404</h1>
      <br />
      <h2>Sorry! This Page Does Not Exist 😢</h2>
      <br />
      <p>Please Visit Us At</p>
      <h5>
        <Link to='/'>Sovaka.com</Link>
      </h5>
    </div>
  );
};

export default PageNotFound;
