import React from "react";

// Styles
import "../../Styles/LandingPage/Stats.css";

export default function Stats() {
    return (
        <section className="stats-section container-fluid">
            <div className="row">
                <div className="stats-col col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <h4 className="stats-100-clients">
                        150+
                        <br />
                        CLIENTS
                    </h4>
                </div>
                <div className="stats-col col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <h4 className="stats-we-keep-count"><strong>WE KEEP COUNT</strong></h4>
                </div>
                <div className="stats-col col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <h4 className="stats-years-experience">
                        2+ YEARS of 
                        <br />
                        EXPERIENCE                        
                    </h4>
                </div>
            </div>
        </section>
    );
}