import React from "react";
import ScrollTop from "../LandingPageSections/ScrollTop";
// Styles
import "../../Styles/LandingPage/About.css";

export default function About() {
  return (
    <>
      <ScrollTop />
      <section className='about-section container-fluid'>
        <div className='about-section-pc'>
          <div className='row'>
            <div className='about-pc-col col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
              <h1 className='about-heading'>
                <strong>ABOUT US</strong>
              </h1>
            </div>
            <div className='about-pc-col col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'></div>
            <div className='about-pc-col col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
              <p>
                At <span>SOVAKA</span>, we're obsessed with pixel perfection and
                seamless user experiences. Our team works tirelessly to craft
                websites and apps that inspire, engage, and drive impressive results.
                From sleek designs that make heads turn to impeccable
                functionality that leaves users in awe! We're here to rescue
                your online presence from the clutches of mediocrity. Get ready
                to level up with us and unleash the true potential of your
                digital journey!
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
