import React from "react";

// Styles
import "../../Styles/LandingPage/Services.css";

export default function Services() {
  return (
    <section className='services-section container-fluid'>
      <h1 className='services-heading'>
        <strong>
          OUR <span>SERVICES</span>
        </strong>
      </h1>
      <div className='services-pc'>
        <div className='row'>
          <div className='services-col col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
            <div className='services-web-development-pc'>
              WEBSITE  <br /> DEVELOPMENT
            </div>
          </div>
          <div className='services-col col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
            <div className='services-app-development-pc'>
              APP <br /> DEVELOPMENT
            </div>
          </div>
          <div className='services-col col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
            <div className='services-social-media-managing-pc'>
              CONTENT <br /> MANAGEMENT
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='services-col col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
            <div className='services-ui-ux-pc'>UI/UX</div>
          </div>
          <div className='services-col col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
            <div className='services-ui-ux-pc'>SEO</div>
          </div>
          <div className='services-col col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
            <div className='services-custom-pc'>
              CUSTOM
              <br />
              SERVICES
            </div>
          </div>
        </div>
      </div>

      {/* Services Mobile */}
      <div className='services-tab-mobile'>
        <div id='carouselIndicators' className='carousel slide'>
          <div className='carousel-indicators'>
            <button
              type='button'
              data-bs-target='#carouselIndicators'
              data-bs-slide-to='0'
              className='active'
              aria-current='true'
              aria-label='Slide 1'></button>
            <button
              type='button'
              data-bs-target='#carouselIndicators'
              data-bs-slide-to='1'
              aria-label='Slide 2'></button>
            <button
              type='button'
              data-bs-target='#carouselIndicators'
              data-bs-slide-to='2'
              aria-label='Slide 3'></button>
            <button
              type='button'
              data-bs-target='#carouselIndicators'
              data-bs-slide-to='3'
              aria-label='Slide 4'></button>
            <button
              type='button'
              data-bs-target='#carouselIndicators'
              data-bs-slide-to='4'
              aria-label='Slide 5'></button>
            <button
              type='button'
              data-bs-target='#carouselIndicators'
              data-bs-slide-to='5'
              aria-label='Slide 6'></button>
          </div>
          <div className='carousel-inner'>
            <div className='carousel-item active'>
              <div className='services-ui-ux-mobile'>
                WEBSITE
                <br /> DEVELOPMENT
              </div>
            </div>
            <div className='carousel-item'>
              <div className='services-social-media-managing-mobile'>
                APP <br /> DEVELOPMENT
              </div>
            </div>
            <div className='carousel-item'>
              <div className='services-web-development-mobile'>
                CONTENT <br /> MANAGEMENT
              </div>
            </div>
            <div className='carousel-item'>
              <div className='services-seo-optimisation-mobile'>UI/UX</div>
            </div>
            <div className='carousel-item'>
              <div className='services-app-development-mobile'>SEO</div>
            </div>
            <div className='carousel-item'>
              <div className='services-custom-mobile'>
                CUSTOM
                <br />
                SERVICES
              </div>
            </div>
          </div>
          <button
            className='carousel-control-prev'
            type='button'
            data-bs-target='#carouselIndicators'
            data-bs-slide='prev'>
            <span
              className='carousel-control-prev-icon'
              aria-hidden='true'></span>
            <span className='visually-hidden'>Previous</span>
          </button>
          <button
            className='carousel-control-next'
            type='button'
            data-bs-target='#carouselIndicators'
            data-bs-slide='next'>
            <span
              className='carousel-control-next-icon'
              aria-hidden='true'></span>
            <span className='visually-hidden'>Next</span>
          </button>
        </div>
      </div>
    </section>
  );
}
