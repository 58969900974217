import React from "react";
import ReactDOM from "react-dom/client";
import { Link, HashRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./Components/Pages/LandingPage";
import Casestudy from "./Components/Pages/Casestudy";
import AboutPage from "./Components/Pages/AboutPage";
import ServicesPage from "./Components/Pages/ServicesPage";
import ClientsPage from "./Components/Pages/ClientsPage";
// import WorksPage from "./Components/Pages/WorksPage";
import ContactPage from "./Components/Pages/ContactPage";
import Terms from "./Components/Pages/Terms";
import Privacy from "./Components/Pages/Privacy";
import Footer from "./Components/LandingPageSections/Footer";
import PageNotFound from "./Components/Pages/PageNotFound";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
// Bootstrap
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
// Custom CSS
import "./Styles/global.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Router basename='/'>
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route exact path='/case_study' element={<Casestudy />} />
        <Route exact path='/aboutpage' element={<AboutPage />} />
        <Route exact path='/services' element={<ServicesPage />} />
        {/* <Route exact path='/works' element={<WorksPage />} /> */}
        <Route exact path='/clients' element={<ClientsPage />} />
        <Route exact path='/contact' element={<ContactPage />} />
        <Route exact path='/terms' element={<Terms />} />
        <Route exact path='/privacy' element={<Privacy />} />
        <Route exact path='*' element={<PageNotFound />} />
      </Routes>
    </Router>
  </>,
);
