import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link, BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Components
import Navbar from "../LandingPageSections/Navbar";
import Hero from "../LandingPageSections/Hero";
import Stats from "../LandingPageSections/Stats";
import Services from "../LandingPageSections/Services";
import TechSkill from "../LandingPageSections/TechSkill";
import About from "../LandingPageSections/About";
import HearOurClients from "../LandingPageSections/HearOurClients";
import Contact from "../LandingPageSections/Contact";
import FAQ from "../LandingPageSections/FAQ";
import Footer from "../LandingPageSections/Footer";
import ScrollTop from "../LandingPageSections/ScrollTop";
// import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

// import HowWeWork from "./../LandingPageSections/HowWeWork";

// styles
import "../../Styles/LandingPage/LandingPage.css";

const LandingPage = () => {
  return (
    <>
      <div className='container'>
        <ScrollTop />
        <Navbar />
        <Hero />
        <Stats />
        <Services />
        <TechSkill />
        <About />
        <HearOurClients />
        {/* <HowWeWork /> */}
        <Contact />
        <FAQ />
      </div>
      <Footer />
    </>
  );
};

export default LandingPage;
