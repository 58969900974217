import React from "react";
import ScrollTop from "../LandingPageSections/ScrollTop";
import Navbar from "../LandingPageSections/Navbar";
import Footer from "../LandingPageSections/Footer";
const Privacy = () => {
  return (
    <>
      <div className='container'>
        <Navbar />

        <ScrollTop />
        <section className='about-section container-fluid'>
          <div className='about-section-pc'>
            <div className='row'>
              <div className='about-pc-col col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                <h1 className='about-heading'>
                  <strong>Privacy and Policy</strong>
                </h1>
              </div>
              <div className='about-pc-col col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'></div>
              <div className='about-pc-col col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                <p>
                  At SOVAKA Software Agency, we are committed to safeguarding
                  your privacy. We collect and process personal information
                  solely for the purpose of providing and improving our software
                  development and related services. We do not share your
                  information with third parties unless required by law or
                  necessary for the provision of our services. We implement
                  appropriate security measures to protect your data and provide
                  you with control over your information. By using our services,
                  you consent to the practices outlined in this privacy policy.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
