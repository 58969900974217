import React from "react";
import Navbar from "../LandingPageSections/Navbar";
import Services from "../LandingPageSections/Services";
import Footer from "../LandingPageSections/Footer";

const ServicesPage = () => {
  return (
    <>
      <div className='container'>
        <Navbar />
        <Services />
      </div>
      <Footer />
    </>
  );
};

export default ServicesPage;
