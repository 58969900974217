import React from "react";

// Styles
import "../../Styles/LandingPage/FAQ.css";

export default function FAQ() {
  return (
    <section className='FAQ-section'>
      <div className='row'>
        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
          <h1 className='FAQ-heading'>
            <strong>
              <span>FAQ's</span> (Frequently Asked Questions)
            </strong>
          </h1>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'></div>
      </div>
      <div className='row'>
        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
          <div className='accordion' id='accordionPanelsStayOpenExample'>
            <div className='accordion-item'>
              <h2 className='accordion-header'>
                <button
                  className='accordion-button'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#panelsStayOpen-collapseOne'
                  aria-expanded='true'
                  aria-controls='panelsStayOpen-collapseOne'>
                  What services do you offer?
                </button>
              </h2>
              <div
                id='panelsStayOpen-collapseOne'
                className='accordion-collapse collapse show'>
                <div className='accordion-body'>
                  At <strong>Sovaka</strong>, we provide a comprehensive range
                  of digital services, including web development, app
                  development, UI/UX design, graphic design, e-commerce
                  solutions, digital marketing, content management systems,
                  custom development, strategic consultation, and ongoing
                  maintenance and support. Our dedicated team of experts is
                  committed to delivering tailored solutions that enhance your
                  online presence and drive business growth.
                  {/* <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow. */}
                </div>
              </div>
            </div>
            <div className='accordion-item'>
              <h2 className='accordion-header'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#panelsStayOpen-collapseTwo'
                  aria-expanded='false'
                  aria-controls='panelsStayOpen-collapseTwo'>
                  How much does your services cost?
                </button>
              </h2>
              <div
                id='panelsStayOpen-collapseTwo'
                className='accordion-collapse collapse'>
                <div className='accordion-body'>
                  The cost of our services at <strong>Sovaka</strong> varies
                  depending on the specific requirements of your project.
                  Factors such as the scope, complexity, technologies involved,
                  and desired features influence the pricing. We believe in
                  providing transparent and customized pricing tailored to each
                  client's needs. Feel free to contact us for a detailed
                  consultation, and we'll be happy to provide you with a
                  personalized quote based on your project's specifics.
                  {/* This is the second item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow. */}
                </div>
              </div>
            </div>
            <div className='accordion-item'>
              <h2 className='accordion-header'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#panelsStayOpen-collapseThree'
                  aria-expanded='false'
                  aria-controls='panelsStayOpen-collapseThree'>
                  How long does it take to complete a website project?
                </button>
              </h2>
              <div
                id='panelsStayOpen-collapseThree'
                className='accordion-collapse collapse'>
                <div className='accordion-body'>
                  The timeline for completing a website project with{" "}
                  <strong>Sovaka</strong> depends on several factors, including
                  the project's complexity, size, features, design intricacies,
                  and the availability of content and feedback from your end.
                  Typically, a basic informational website could take a few
                  days, while more complex sites with advanced functionality
                  might require a few weeks. We provide a detailed project plan
                  and timeline during the initial consultation, outlining key
                  milestones and estimated completion dates based on your
                  project's unique requirements. Our goal is to deliver a
                  high-quality website within an agreed-upon timeframe while
                  ensuring attention to detail and excellence.
                  {/* This is the third item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow. */}
                </div>
              </div>
            </div>
            <div className='accordion-item'>
              <h2 className='accordion-header'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#panelsStayOpen-collapseFour'
                  aria-expanded='false'
                  aria-controls='panelsStayOpen-collapseFour'>
                  What is your process for designing and developing websites?
                </button>
              </h2>
              <div
                id='panelsStayOpen-collapseFour'
                className='accordion-collapse collapse'>
                <div className='accordion-body'>
                  Website Design and Development Process at{" "}
                  <strong>Sovaka</strong>:<br />
                  Our process follows a structured approach to deliver
                  outstanding websites:
                  <ul
                    style={{
                      marginTop: "20px",
                    }}>
                    <li>
                      <strong>Planning: </strong>We understand your needs, set
                      goals, and define the project scope for a solid and future
                      proof workflow.
                    </li>
                    <br />
                    <li>
                      <strong>Design: </strong>We create wireframes and a
                      visually appealing design for your approval as our team
                      has experienced UI/UX Designers .
                    </li>
                    <br />
                    <li>
                      <strong>Development: </strong>Our team codes the website
                      with cutting-edge technologies like React, Node, Express
                      etc.
                    </li>
                    <br />
                    <li>
                      <strong>Testing: </strong>Rigorous testing ensures
                      functionality, compatibility, and performance.
                    </li>
                    <br />
                    <li>
                      <strong>Feedback: </strong>Your insights guide refinements
                      for the best outcome.
                    </li>
                    <br />
                    <li>
                      <strong>Optimisation: </strong>We fine-tune for speed,
                      SEO, and user accessibility.
                    </li>
                    <br />
                    <li>
                      <strong>Launch: </strong>The website goes live on your
                      chosen hosting platform.
                    </li>
                    <br />
                    <li>
                      <strong>Support: </strong>Post-launch, we provide ongoing
                      assistance and updates.
                    </li>
                    <br />
                  </ul>
                  Our process ensures a seamless journey from concept to a
                  captivating and functional website.
                  {/* <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow. */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
