import React, { useState } from "react";
import { useRef } from "react";
import { Link, BrowserRouter as Router, Routes, Route } from "react-router-dom";
// Styles
import "../../Styles/LandingPage/Navbar.css";
// Images
import LOGO from "../../Resources/Theme/SOVAKA-LOGO.png";
// import HAMBURGER from "../../Resources/Theme/hamburger.svg";
// import CLOSE from "../../Resources/Theme/hamburger-close.svg";

import Hamburger from "hamburger-react";

function Navbar() {
  const navRef = useRef();

  const ShowNavbar = () => {
    navRef.current.classList.add("responsive-nav");

    // If the navbar is open, then hide the body scroll bar and vice versa
    document.body.style.overflow = navRef.current.classList.contains(
      "responsive-nav",
    )
      ? "hidden"
      : "auto";
  };

  const CloseNavbar = () => {
    navRef.current.classList.remove("responsive-nav");
    document.body.style.overflow = "auto";
    // document.body.style.overflow = navRef.current.classList.contains("responsive-nav") ? "hidden" : "auto";
  };

  const [isOpen, setOpen] = useState(false);

  return (
    <section className='navbar-section container-fluid'>
      <div className='navbar-pc row'>
        <div className='navbar-item navbar-logo col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
          <Link to='/'>
            <img className='sovaka-logo' src={LOGO} alt='sovaka-logo' />
          </Link>
          <Link className='logo-route-link' to='/'>
            <h1>SOVAKA</h1>
          </Link>
        </div>
        <div className='navbar-item navbar-links col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
          <ul>
            <li className='navbar-link'>
              <Link to='/aboutpage'>About</Link>
            </li>
            <li className='navbar-link'>
              <Link to='/services'>Services</Link>
            </li>
            {/* <li className='navbar-link'>
              <Link to='/works'>Works</Link>
            </li> */}
            <li className='navbar-link'>
              <Link to='/clients'>Clients</Link>
            </li>
          </ul>
        </div>
        <div className='navbar-item navbar-contact col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
          <Link style={{ textDecoration: "0px" }} to='/contact'>
            <button type='button' className='btn btn-primary'>
              CONTACT-US
            </button>
          </Link>
        </div>
      </div>
      <div className='navbar-mobile row'>
        <div className='navbar-mobile-item navbar-mobile-logo col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
          <Link to='/'>
            <img className='sovaka-logo' src={LOGO} alt='sovaka-logo' />
          </Link>
        </div>
        <div className='navbar-mobile-item navbar-mobile-sovaka col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
          <Link className='logo-route-link' to='/'>
            <h1>SOVAKA</h1>
          </Link>
        </div>
        <div className='navbar-mobile-item col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
          <div className='navbar-mobile-menu' ref={navRef}>
            <Link to='/aboutpage' onClick={CloseNavbar}>
              About
            </Link>
            <Link to='/services' onClick={CloseNavbar}>
              Services
            </Link>
            {/* <Link to='/works' onClick={CloseNavbar}>
              Works
            </Link> */}
            <Link to='/clients' onClick={CloseNavbar}>
              Clients
            </Link>
            <Link to='/contact' onClick={CloseNavbar}>
              Contact Us
            </Link>
            {/* <Hamburger
					className="nav-btn nav-close-btn"
					onClick={ShowNavbar}>
				</Hamburger> */}
          </div>

          {/* issue: Hamburger button is not fixed */}

          <Hamburger
            overFlow='hidden'
            className='hamburger'
            toggled={isOpen}
            toggle={setOpen}
            onToggle={(toggled) => {
              if (toggled) {
                // open a menu
                ShowNavbar();
              } else {
                // close a menu
                CloseNavbar();
              }
            }}
          />
        </div>
      </div>
    </section>
  );
}

export default Navbar;
