import React from "react";
import Navbar from "../LandingPageSections/Navbar";
import Contact from "../LandingPageSections/Contact";
import Footer from "../LandingPageSections/Footer";
const ContactPage = () => {
  return (
    <>
      <div className='container'>
        <Navbar />
        <Contact />
      </div>
      <Footer></Footer>
    </>
  );
};

export default ContactPage;
