import React from "react";
import { Link, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "../LandingPageSections/Footer";
const Casestudy = () => {
  return (
    <>
      <div>
        <h2>This is from Case Study Page!</h2>
        <div>
          <Link to='/case_study'>Go To Case Study Page 👉</Link>
          <Link to='/about'>Go To About Page 👉</Link>
          <Link to='/'>Go To Home Page 👉</Link>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Casestudy;
