import React from "react";
import ScrollTop from "../LandingPageSections/ScrollTop";
import Navbar from "../LandingPageSections/Navbar";
import Footer from "../LandingPageSections/Footer";
const Terms = () => {
  return (
    <>
      <div className='container'>
        <Navbar />

        <ScrollTop />
        <section className='about-section container-fluid'>
          <div className='about-section-pc'>
            <div className='row'>
              <div className='about-pc-col col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                <h1 className='about-heading'>
                  <strong>Terms and Conditions </strong>
                </h1>
              </div>
              <div className='about-pc-col col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'></div>
              <div className='about-pc-col col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                <p>
                  By accessing and utilizing the services provided by SOVAKA,
                  you accept and agree to be bound by these Terms and
                  Conditions. SOVAKA offers software development and related
                  services, with payment terms specified separately. All
                  intellectual property remains SOVAKA's unless otherwise
                  agreed. Both SOVAKA and the client commit to maintaining the
                  confidentiality of proprietary information. While SOVAKA
                  strives for high-quality service and SOVAKA is not liable for
                  any damages. Either party can terminate the agreement with
                  written notice. These terms are governed by [Your
                  Jurisdiction] laws, subject to occasional updates communicated
                  to clients, and constitute the entire agreement between the
                  parties.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
