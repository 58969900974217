import React from "react";

// Images
// ROW_1
import XD from "../../Resources/Logos/adobe-xd-img.png";
import CSS from "../../Resources/Logos/css-img.png";
import Mongo from "../../Resources/Logos/mongo-img.png";
import Figma from "../../Resources/Logos/figma-img.png";
import JS from "../../Resources/Logos/js-img.png";
// ROW_2
import HTML from "../../Resources/Logos/html-img.png";
import NODEJS from "../../Resources/Logos/nodejs-img.png";
import FIREBASE from "../../Resources/Logos/firebase-img.png";
import CPP from "../../Resources/Logos/cpp-img.png";
import REACT from "../../Resources/Logos/react-logo-1-img.png";
// ROW_3
import FRAMER from "../../Resources/Logos/framer-motion-img.png";
import EXPRESS from "../../Resources/Logos/express-img.png";
import ILLUSTRATOR from "../../Resources/Logos/adobe-illustrator-img.png";
import CHAKRAUI from "../../Resources/Logos/chakra-ui-img.png";
import TENSORFLOW from "../../Resources/Logos/tensorflow-img.png";

// Styles
import "../../Styles/LandingPage/TechSkill.css";

export default function TechSkill() {
  return (
    <section className='tech-skill-section container-fluid'>
      <h1 className='tech-skill-heading'>
        <strong>
          <span>TECHNOLOGIES & </span>
          SKILLS
          <span>&nbsp;WE KNOW</span>
        </strong>
      </h1>
      <div className='row'>
        <div className='tech-skill-col col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <img src={REACT} alt='react-img' />
        </div>
        <div className='tech-skill-col col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <img src={NODEJS} alt='nodejs-img' />
        </div>
        <div className='tech-skill-col col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <img src={EXPRESS} alt='express-img' />
        </div>
        <div className='tech-skill-col col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <img src={Mongo} className='tech-skill-mongo' alt='mongo-img' />
        </div>
        <div className='tech-skill-col col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <img src={JS} className='tech-skill-js' alt='js-img' />
        </div>
      </div>
      <div className='row'>
        <div className='tech-skill-col col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <img src={HTML} alt='html-img' />
        </div>
        <div className='tech-skill-col col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <img src={CSS} className='tech-skill-css' alt='css-img' />
        </div>
        <div className='tech-skill-col col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <img src={FIREBASE} alt='firebase-img' />
        </div>
        <div className='tech-skill-col col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <img src={CPP} alt='cpp-img' />
        </div>
        <div className='tech-skill-col col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <img src={XD} className='tech-skill-xd' alt='adobe-xd-img' />
        </div>
      </div>
      <div className='row'>
        <div className='tech-skill-col col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <img src={FRAMER} alt='framer-motion-img' />
        </div>
        <div className='tech-skill-col col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <img src={Figma} className='tech-skill-figma' alt='figma-img' />
        </div>
        <div className='tech-skill-col col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <img src={ILLUSTRATOR} alt='adobe-illustrator-img' />
        </div>
        <div className='tech-skill-col col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <img src={CHAKRAUI} alt='chakra-ui-img' />
        </div>
        <div className='tech-skill-col col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <img src={TENSORFLOW} alt='tensorflow-img' />
        </div>
      </div>
    </section>
  );
}
