import React from "react";
import { Link } from "react-router-dom";
import twitterIcon from "../../Resources/Images/twitter.svg";
import instagramIcon from "../../Resources/Images/instagram.svg";
import linkedinIcon from "../../Resources/Images/linkedin.svg";

// styles
import "../../Styles/LandingPage/Footer.css";
const Footer = () => {
  return (
    <div className='footer-container'>
      <footer className='container'>
        <div className='footer-navs div1'>
          <Link to='/'>
            <li>Home</li>
          </Link>
          <Link to='/clients'>
            <li>Reviews</li>
          </Link>
          <Link to='/aboutpage'>
            <li>About Us</li>
          </Link>
          <Link to='/contact'>
            <li>Contact Us</li>
          </Link>
        </div>
        <div className='footer-socials div2'>
          <Link to='https://www.twitter.com/sovaka_official/' target='_blank'>
            <img
              src={twitterIcon}
              title='@sovaka_official'
              alt='Twitter[@sovaka_official]'
            />
          </Link>
          <Link to='https://www.instagram.com/sovaka_official/' target='_blank'>
            <img
              src={instagramIcon}
              title='@sovaka_official'
              alt='Instagram[@sovaka_official]'
            />
          </Link>
          <Link to='https://www.linkedin.com/company/sovaka/' target='_blank'>
            <img src={linkedinIcon} title='Sovaka' alt='LinkedIn-logo' />
          </Link>
        </div>
        <div className='footer-cc div3'>
          <li>Copyright © [2023] SOVAKA. All rights reserved.</li>
        </div>
        <div className='footer-conditions div4'>
          <Link to='/privacy'>
            <li>Privacy Policy</li>
          </Link>

          <Link to='/terms'>
            <li>Terms & Conditions</li>
          </Link>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
