import React from "react";
import HearOurClients from "../LandingPageSections/HearOurClients";
import Navbar from "../LandingPageSections/Navbar";
import Footer from "../LandingPageSections/Footer";
const ClientsPage = () => {
  return (
    <>
      <div className='container'>
        <Navbar />
        <HearOurClients />
      </div>
      <Footer></Footer>
    </>
  );
};

export default ClientsPage;
