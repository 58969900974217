/**
 * APARUP's TASK: Re-direct the links (anchor tags) to the respective pages.
 * Line numbers: 25, 26, 35, 36
 */

import React from "react";
import { Link } from "react-router-dom";
// Styles
import "../../Styles/LandingPage/Hero.css";

// Images
import HERO_IMG from "../../Resources/Theme/HERO_IMG.png";

// Animations
import Lottie from "react-lottie";
import anim from "../../Resources/Animations/HomePageAnim.json";

const Hero = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: anim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <section className='hero-section container-fluid'>
      <div className='row'>
        <div className='hero-col-1 d-flex col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
          <div className='hero-col-1-box d-flex p-3'>
            <p className='hero-come-online'>COME ONLINE</p>
            <h1 className='hero-boldness'>
              <strong>BOLDNESS</strong> not only sticks to our font, but{" "}
              <span className='hero-work'>
                <strong>WORK</strong>
              </span>{" "}
              also.
            </h1>
            <p>
              We create stunning and pixel perfect websites and applications for
              you, So you don't have to worry about your online presence 🌟{" "}
            </p>
            <div className='hero-buttons-pc hero-buttons'>
              <a
                href='https://calendly.com/official-sovaka/15min'
                target='_blank'
                className='hero-book-a-meeting p-2'
                rel='noreferrer'>
                Book-A-Meeting
              </a>
              <Link to='/clients' className='hero-reviews p-2'>
                REVIEWS
              </Link>
            </div>
          </div>
        </div>
        <div className='hero-col-2 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
          <div>
            <Lottie
              options={defaultOptions}
              isClickToPauseDisabled={true}
              // height={470}
              // width={470}
            />
          </div>
        </div>
      </div>
      <div className='hero-buttons-mobile hero-buttons'>
        <a
          href='https://calendly.com/official-sovaka/15min'
          target='_blank'
          className='hero-book-a-meeting p-2'
          rel='noreferrer'>
          Book-A-Meeting
        </a>
        <Link to='/clients' className='hero-reviews p-2'>
          REVIEWS
        </Link>
      </div>
    </section>
  );
};

export default Hero;
